import React from "react";
import NavBar from "../components/NavBar";
import "../App.css";

export default function Splash(props) {
  const showItUrl = "https://fiddle-school-1-1730845973185.showitpreview.com/mountain-retreat-student-info-page-2025";
  return (
    <div className="splash-container">
      <NavBar page={props.page} />
      <iframe
        className="splash-iframe"
        src={props.page ? showItUrl + props.page : showItUrl}
      />
    </div>
  );
}
