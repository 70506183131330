import React from "react";
import Play from "../assets/play.png";
import Circle from "../assets/Circle2.avif";
import video1 from "../assets/video1Screenshot.png";
import HeaderImageWhite from "../assets/fiddleschool-logo.png";
import { useNavigate } from "react-router-dom";
import "../App.css";

export default function Profile(props) {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#f8f6f1",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="navbar">
          <div
            style={{
              marginLeft: 85,
              flex: 1,
              display: "flex",
              alignItems: "flex-start",
            }}
            onClick={() => {
              navigate("/courses");
            }}
          >
            <img
              src={HeaderImageWhite}
              style={{
                width: 135,
                marginLeft: 30,
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
          </div>

          <div
            onClick={() => {
              navigate("/courses");
            }}
            className="nav-item nav-item-unselected"
          >
            Courses
          </div>
          <div
            onClick={() => {
              navigate("/tracks");
            }}
            className="nav-item"
          >
            Tracks & Playlists
          </div>
          <div
            className="nav-item"
            onClick={() => {
              alert(
                "Once we transfer the domain, we can iframe circle communnity in to the page"
              );
            }}
          >
            Community
          </div>
          <div
            onClick={() => {
              navigate("/shop");
            }}
            className="nav-item nav-right"
          >
            Store
          </div>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "100px",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <main className="dashboard-main">
            <section className="action-cards">
              <ActionCard
                coverImage={video1}
                title="Today's Lesson"
                description="Starter Kit: Unit 1, Video 1"
                onClick={() => navigate("/courses")}
              />
              <ActionCard
                image={Play}
                title="Fiddle School Radio"
                description="Play along: Dixie Hoedown with fast guitar..."
                onClick={() => navigate("/tracks")}
              />
              <ActionCard
                title="Community"
                image={Circle}
                description="Connect with other Fiddlers!"
                onClick={() => {
                  navigate("/circle");
                }}
              />
            </section>
            <section className="progress-section">
              <h2 style={{ fontSize: 28, color: "black", marginTop: 10 }}>
                Progress
              </h2>
              <div style={{ fontSize: 22, marginBottom: 25, color: "black" }}>
                Lesson 1
              </div>
              <div className="progress-bar-profile">
                <div
                  className="progress-bar-fill-profile"
                  style={{ width: "20%" }}
                ></div>
              </div>
              <div className="progress-stats">
                <div className="stat-card">
                  <p style={{ fontWeight: "500", fontSize: 18 }}>
                    Days in a Row
                  </p>
                  <div
                    style={{ fontSize: 44, marginTop: 28, fontWeight: "bold" }}
                  >
                    1
                  </div>
                </div>
                <div className="stat-card">
                  <p style={{ fontWeight: "500", fontSize: 18 }}>
                    Total Time Practicing
                  </p>
                  <div
                    style={{
                      fontSize: 44,
                      marginTop: 28,
                      fontWeight: "bold",
                    }}
                  >
                    1 hr
                  </div>
                </div>
                <div className="stat-card">
                  <p style={{ fontWeight: "500", fontSize: 18 }}>
                    Estimated Lesson Left
                  </p>
                  <div
                    style={{
                      fontSize: 44,
                      marginTop: 28,
                      fontWeight: "bold",
                    }}
                  >
                    7 hrs
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}

const ActionCard = ({
  title,
  description,
  image,
  icon,
  onClick,
  coverImage,
}) => (
  <div
    className="action-card"
    onClick={onClick}
    style={{ position: "relative" }}
  >
    {coverImage && (
      <img
        src={coverImage}
        style={{
          width: "100%",
          height: 160,
          position: "absolute",
          top: 0,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,

          objectFit: "cover",
        }}
        alt={title}
        className="action-card-image"
      />
    )}

    {image && <img src={image} alt={title} className="action-card-image" />}
    {icon && <img src={icon} alt={title} className="action-card-icon" />}
    <div style={{ marginTop: coverImage ? 80 : 0 }}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);
