import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";

import ControlBar from "../components/ControlBar";
import RadioSidebar from "../components/RadioSidebar";
import NavBarHome from "../components/NavBarHome";
import "../App.css";
import "../css/Radio.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_TOKEN,
  authDomain: "fiddleschool-6fa40.firebaseapp.com",
  projectId: "fiddleschool-6fa40",
  storageBucket: "fiddleschool-6fa40.appspot.com",
  messagingSenderId: "997131554190",
  appId: "1:997131554190:web:1b16cfa7a7e5b7a72868df",
  measurementId: "G-THQHHBHPFB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function Radio(props) {
  const [trackType, setTrackType] = useState(0);
  const [selectedTrack, setSelectedTrack] = useState(0);
  const [trackTypes, setTrackTypes] = useState([]);
  const [tuneFiles, setTuneFiles] = useState([]);
  const [trackTitles, setTrackTitles] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const nextTrack = () => {
    setSelectedTrack(selectedTrack + 1);
  };

  const previousTrack = () => {
    if (selectedTrack > 0) setSelectedTrack(selectedTrack - 1);
  };

  const shuffleTracks = () => {};

  const replayTrack = () => {};

  // Fetch and reassemble data from Firestore
  const fetchData = async () => {
    try {
      // get track types
      const docRef = doc(db, "radio", "trackTypes");

      // Get the document snapshot
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // Extract the trackTypes array from the document
        setTrackTypes(docSnap.data().data);
      } else {
        console.log("No such document found in the radio collection!");
      }

      // Reference the 'radio' collection
      const radioCollection = collection(db, "radio");

      // Get all documents from the collection
      const querySnapshot = await getDocs(radioCollection);

      // Temporary arrays for reassembling nested arrays
      const tempTuneFiles = [];
      const tempTrackTitles = [];

      // Loop through each document
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        // Check the document ID and push the data to the corresponding array
        if (doc.id.includes("tuneFilesGroup")) {
          tempTuneFiles.push(data.data); // Add to tuneFiles array
        } else if (doc.id.includes("trackTitlesGroup")) {
          tempTrackTitles.push(data.data); // Add to trackTitles array
        }
      });

      // Set state variables
      setTuneFiles(tempTuneFiles);
      setTrackTitles(tempTrackTitles);
    } catch (error) {
      console.error("Error fetching data from Firestore:", error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email == "null" || !email) {
      window.location.href = "/login";
    }
  }, []);

  return (
    <>
      {trackTitles.length == 0 ||
      tuneFiles.length == 0 ||
      !trackTypes ||
      trackTypes.length == 0 ? (
        <></>
      ) : (
        <>
          <RadioSidebar
            stations={trackTypes}
            selectedStation={trackType}
            onSelectStation={(station) => {
              setTrackType(station);
            }}
            setSelectedTrack={setSelectedTrack}
          />
          <div className="main-container radio-container">
            <NavBarHome page={"radio"} />
            <div className="radio-container-inner fade-in">
              <div className="radio-header-container">
                <div className={"radio-header"}>
                  <div className="radio-playlist-text">Playlist</div>
                  <div className="radio-type-text">{trackTypes[trackType]}</div>
                  <div className="radio-songs-text">
                    {trackTitles[trackType].length} songs
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "none",
                }}
                className="radio-header-small"
              >
                <div className="radio-header-small-container">
                  <div className="radio-header-small-inner-container">
                    {trackTypes.map((station, index) => (
                      <div
                        key={index}
                        className={`station-item-radio radio-station-item-small ${
                          trackType === index ? "selected" : ""
                        }`}
                        onClick={() => {
                          setTrackType(index);
                          setSelectedTrack(0);
                        }}
                      >
                        {station}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="radio-tracks-container">
                {trackTitles[trackType].map((item, index) => {
                  return (
                    <div>
                      <div
                        style={{
                          fontWeight:
                            selectedTrack == index ? "bold" : "normal",
                        }}
                        onClick={() => {
                          setSelectedTrack(index);
                        }}
                        className={`radio-track Button track-list-item ${
                          selectedTrack == index ? "selected" : ""
                        }`}
                      >
                        <strong>{index + 1}</strong>&nbsp;&nbsp;&nbsp;
                        <div>{item}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <ControlBar
              tuneFile={tuneFiles[trackType][selectedTrack]}
              trackTitle={trackTitles[trackType][selectedTrack]}
              trackGenre={trackTypes[trackType]}
              isPlaying={isPlaying}
              onPlayPause={togglePlayPause}
              onNext={nextTrack}
              onPrevious={previousTrack}
              onShuffle={shuffleTracks}
              onReplay={replayTrack}
              setIsPlaying={setIsPlaying}
            />
          </div>
        </>
      )}
    </>
  );
}
