import React, { useEffect } from "react";

const LessonVideos = ({
  isOpen,
  onClose,
  videos,
  setVideoIndex,
  videoIndex,
  setCurrentVideo,
  currentUnit,
}) => {
  if (!isOpen) return null; // Only render if open
  return (
    <div className="modal-background">
      <div
        className="modal-container"
        style={{
          height: "85%",
          width: "85vw",
          maxWidth: 950,
          position: "relative",
        }}
      >
        <h2 className="modal-title" style={{ marginBottom: 20 }}>
          Lesson {currentUnit} Videos
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            height: "80%",
            overflow: "scroll",
          }}
        >
          {videos != null && videos != undefined ? (
            Object.keys(videos).map((item, index) => {
              const videoString = "video" + (index + 1);

              return (
                <div
                  className="Button track-item"
                  style={{
                    backgroundColor: "#51789f",
                    borderRadius: 4,
                    minHeight: 40,
                    marginBottom: 4,
                    marginTop: 4,
                    paddingLeft: 10,
                    paddingRight: 25,
                    alignItems: "center",
                    display: "flex",
                    cursor: "pointer",
                    borderColor: "black",
                    borderStyle: "solid",
                    borderWidth: index == videoIndex ? 3.5 : 0,
                    position: "relative",
                    fontWeight: index == videoIndex ? "700" : "normal",
                  }}
                  onClick={() => {
                    setCurrentVideo(videoString);
                    setVideoIndex(index);
                    setTimeout(() => {
                      onClose();
                    }, 200);
                  }}
                >
                  <div style={{ paddingRight: 20 }}>
                    {videos[videoString] && videos[videoString].title
                      ? videos[videoString].title
                      : ""}
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>

        <div style={{ position: "absolute", bottom: 40, right: 0, left: 0 }}>
          <button className="modal-button-close modal-button" onClick={onClose}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default LessonVideos;
