import React, { useEffect, useState } from "react";
import NavBarHome from "../components/NavBarHome";
import "react-activity/dist/library.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "../App.css";
import "../css/Course.css";



function FsmrStudentInfo2025(props) {
  const [userEmail, setUserEmail] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email === "null" || !email) {
      window.location.href = "/login";
    } else setUserEmail(email);
  }, []);
  // Detect the screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 840);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window?.innerWidth]);
 

  const showItUrl = "https://fiddle-school-1-1730845973185.showitpreview.com/mountain-retreat-student-info-page-2025";

  return (
    <>
      <div className="main-container radio-container">
        <NavBarHome page="fsmr-student-info-2025"/>
        <>
          <iframe
            className="splash-iframe-fsmr-student-info-2025"
            src={props.page ? showItUrl + props.page : showItUrl}
            title="fsmr student info 2025"
          />
        </>
      </div>
    </>
  );
}

export default FsmrStudentInfo2025;
