import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Splash from "./routes/Splash";
import NewSplash from "./routes/NewSplash";
import Login from "./routes/Login";
import SignUp from "./routes/SignUp";
import Home from "./routes/Home";
import Profile from "./routes/Profile";
import Radio from "./routes/Radio";
import Circle from "./routes/Circle";
import MountainRetreat2025 from "./routes/MountainRetreat2025";
import Retreat from "./routes/Retreat";
import ResetPassword from "./routes/ResetPassword";
import "./App.css";
import FsmrStudentInfo2025 from "./routes/FsmrStudentInfo2025";

function App() {
  const [email, setEmail] = useState(localStorage.getItem("email"));

  useEffect(() => {
    const handleStorageChange = () => {
      setEmail(localStorage.getItem("email"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        {/* Splash Pages */}
        <Route path="/" element={<Splash />} />
        <Route path="/memberships" element={<Splash page="memberships" />} />
        <Route path="/fiddle-school" element={<Splash page="fiddle-school" />} />
        <Route path="/mastery" element={<Splash page="mastery" />} />

        {/* Logika dibalik: Jika email kosong, tampilkan Splash */}
        <Route path="/retreats" element={!email || email == "null" ? <Splash page="retreat" /> : <Retreat />} />
        <Route path="/about" element={<Splash page="about" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/courses" element={<Home />} />
        <Route path="/profile" element={<Profile />} />

        {/* Logika dibalik untuk halaman Mountain Retreat */}
        <Route 
          path="/mountain-retreat-2025" 
          element={!email || email == "null" ? <Splash page="mountain-retreat-2025" /> : <MountainRetreat2025 />} 
        />
        <Route 
          path="/fsmr-student-info-2025" 
          element={!email || email == "null" ? <NewSplash/> : <FsmrStudentInfo2025/>} 
        />
        <Route path="/tracks" element={<Radio />} />
        <Route path="/circle" element={<Circle />} />
      </Routes>
    </div>
  );
}

export default App;
