import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  getFirestore,
  collection,
  getDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import "../css/GoldenFiddle.css";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_TOKEN,
  authDomain: "fiddleschool-6fa40.firebaseapp.com",
  projectId: "fiddleschool-6fa40",
  storageBucket: "fiddleschool-6fa40.appspot.com",
  messagingSenderId: "997131554190",
  appId: "1:997131554190:web:1b16cfa7a7e5b7a72868df",
  measurementId: "G-THQHHBHPFB",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const GoldenFiddle = ({ isOpen, onClose, setSelectedKit, changeCourse }) => {
  const [contentData, setContentData] = useState({});
  const [currentClasses, setCurrentClasses] = useState([]);
  const [openGoldenFIddleMenu, setOpenGoldenFIddleMenu] = useState("");
  const [openGoldenFIddleMenuSubSection, setOpenGoldenFIddleMenuSubSection] =
    useState("");
  // let globalIndex = 19;

  // const globalIndexRef = useRef(globalIndex);
  const [globalIndex, setGlobalIndex] = useState(19);

  const fetchContentData = async () => {
    try {
      const contentData = {};

      const sections = [
        "FiddleTuneFrontiers",
        "SwingImprovWorkouts",
        "TexasTunes",
      ];

      const fiddleTuneFrontiersSubSections = [
        "Seasonal",
        "BodyCare&InjuryPrevention",
        "Practice&PerformanceStrategies",
        "TheFiddler'sArchive",
        "Technique,Rhythm,&Groove",
        "ImprovFoundations,Theory,&Jammming",
        "Guitar&Accompaniment",
        "OtherFiddleStyles",
      ];

      const swingImprovWorkoutsSubSections = [
        "SwingCalisthenics",
        "WesternSwingWorkouts",
        "SwingWorkouts",
      ];

      const texasTunesSubSections = [
        "Breakdowns",
        "Waltzes",
        "TunesofChoice",
        "ShowTunes&Other",
      ];

      for (const section of sections) {
        let sectionData = {};

        // Jika section memiliki sub-sections (FiddleTuneFrontiers & SwingImprovWorkouts)
        if (
          section === "FiddleTuneFrontiers" ||
          section === "SwingImprovWorkouts" ||
          section === "TexasTunes"
        ) {
          const subSections =
            section === "FiddleTuneFrontiers"
              ? fiddleTuneFrontiersSubSections
              : section === "SwingImprovWorkouts"
              ? swingImprovWorkoutsSubSections
              : texasTunesSubSections;

          const subCollectionPromises = subSections.map(async (subSection) => {
            // Mengakses koleksi yang berisi dokumen item (misalnya item1, item2)
            const subCollectionRef = collection(
              db,
              "goldenFiddle",
              section,
              subSection
            );
            const subCollectionSnap = await getDocs(subCollectionRef);

            let subSectionData = {};

            // Ambil dan urutkan dokumen berdasarkan ID (item1, item2, ...)
            const sortedDocs = subCollectionSnap.docs.sort((a, b) => {
              const numA = parseInt(a.id.replace("item", ""), 10);
              const numB = parseInt(b.id.replace("item", ""), 10);
              return numA - numB;
            });

            // Loop melalui dokumen yang sudah diurutkan
            for (const doc of sortedDocs) {
              const itemId = doc.id;
              const itemData = doc.data(); // Ambil data untuk setiap item

              subSectionData[itemId] = itemData; // Menyimpan data setiap item dengan ID masing-masing
            }

            return { [subSection]: subSectionData };
          });

          // Tunggu semua data dari sub-seksi
          const subCollectionsData = await Promise.all(subCollectionPromises);
          subCollectionsData.forEach((subSectionData) => {
            Object.assign(sectionData, subSectionData); // Gabungkan data sub-seksi
          });
        }
        // Jika section tanpa sub-seksi (misalnya "TexasTunes")
        else {
          // Mengakses dokumen langsung dari koleksi utama
          const sectionRef = collection(db, "goldenFiddle", section); // Mengakses koleksi langsung
          const sectionSnap = await getDocs(sectionRef); // Mengambil data dari koleksi

          let sectionItems = {};

          sectionSnap.forEach((doc) => {
            sectionItems[doc.id] = doc.data(); // Ambil data dari dokumen setiap item
          });

          sectionData = { items: sectionItems }; // Menyimpan data semua item dalam satu section
        }

        contentData[section] = sectionData; // Gabungkan data untuk setiap section
      }

      setContentData(contentData); // Update state dengan data yang sudah diambil
      return contentData;
    } catch (error) {
      console.error("Error fetching content data:", error); // Menangani error jika ada
    }
  };
  const fetchCurrentClasses = async () => {
    try {
      // Reference the `goldenFiddle/currentClasses` document
      const docRef = doc(db, "goldenFiddle", "currentClasses");

      // Fetch the document snapshot
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();

        // Convert the restructured data back into a nested array
        const currentClasses = Object.keys(data)
          .sort((a, b) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1])) // Sort by section index
          .map((key) => data[key]); // Extract arrays in order
        return currentClasses;
      } else {
        console.log("No such document in goldenFiddle collection!");
      }
    } catch (error) {
      console.error("Error fetching currentClasses:", error);
    }
  };

  useEffect(() => {
    // Usage
    fetchContentData().then((contentData) => {
      setContentData(contentData);
    });

    fetchCurrentClasses().then((currentClasses) => {
      setCurrentClasses(currentClasses);
    });
  }, []);

  const currentClassesSections = [
    "Fiddle Tune Frontiers",
    "Swing Improv Workouts",
    "Texas Tunes",
  ];

  useEffect(() => {
    fetchContentData().then(setContentData);
    fetchCurrentClasses().then(setCurrentClasses);
  }, []);

  let globalIndexCurrentClasses = 13;
  const globalIndexMapping = useMemo(() => {
    let indexCounter = 19; // Mulai dari 19 atau angka yang sesuai
    const mapping = {};

    Object.keys(contentData).forEach((sectionName) => {
      mapping[sectionName] = {};

      Object.keys(contentData[sectionName]).forEach((subSectionKey) => {
        mapping[sectionName][subSectionKey] = {};

        Object.keys(contentData[sectionName][subSectionKey]).forEach(
          (itemKey) => {
            mapping[sectionName][subSectionKey][itemKey] = indexCounter;
            indexCounter++;
          }
        );
      });
    });

    return mapping;
  }, [contentData]); // Add globalIndex as dependency to keep it updated
  const renderSection = (sectionName, items) => (
    <div key={sectionName}>
      <h2
        className="section-title-goldenFIddle-library"
        style={{
          backgroundColor:
            sectionName === "FiddleTuneFrontiers"
              ? "#c75729"
              : sectionName === "SwingImprovWorkouts"
              ? "#4479A5"
              : "#8F150A",
          color: "#fff",
          textAlign: "left",
          fontSize: 26,
          borderRadius: 10,
          // marginTop: sectionName !== "SwingImprovWorkouts" ? 50 : 20,
        }}
        onClick={() => setOpenGoldenFIddleMenu(sectionName)}
      >
        {sectionName.replace(/([A-Z]|&)/g, " $1").trim()}
        <span className={sectionName === openGoldenFIddleMenu ? "open" : ""}>
          <MdKeyboardArrowDown className="arrow-icon-goldenFiddleLibrary" />
        </span>
      </h2>
      {/* Display subsections only when the section is opened */}
      {sectionName === openGoldenFIddleMenu && (
        <>
          {Object.keys(contentData[sectionName]).map((subSectionKey) => (
            <div key={subSectionKey}>
              <h3
                 className={`section-title-goldenFIddle-library fade-in`}
                style={{
                  color:
                    subSectionKey === openGoldenFIddleMenuSubSection
                      ? "#000000"
                      : "",
                  textAlign: "left",
                  marginLeft: 40,
                  fontSize: 22,
                }}
                onClick={() => setOpenGoldenFIddleMenuSubSection(subSectionKey)}
              >
                {subSectionKey
                  .replace(/([A-Z]|&)/g, " $1")
                  .replace(/(\s*)of(\s*)/g, " of ")
                  .trim()}
              </h3>
              {/* Display items in sub-section */}
              {subSectionKey === openGoldenFIddleMenuSubSection && (
                <ul
                  className="section-list fade-in"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  {Object.keys(contentData[sectionName][subSectionKey]).map(
                    (itemKey, index) => {
                      const currentLibraryIndex =
                        globalIndexMapping[sectionName][subSectionKey][itemKey];
                      return (
                        <li
                          key={itemKey}
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "flex-start",
                            textAlign: "left",
                            paddingLeft: 80,
                          }}
                        >
                          {index + 1}.&nbsp;
                          <div
                            onClick={() => {
                              changeCourse(
                                contentData[sectionName][subSectionKey][itemKey]
                                  .slug,
                                true
                              );
                              setSelectedKit(currentLibraryIndex);
                              onClose();
                            }}
                            style={{ flex: 1, marginRight: 20 }}
                            className="golden-fiddle-currentclasses-link"
                          >
                            {
                              contentData[sectionName][subSectionKey][itemKey]
                                .name
                            }
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );

  if (!isOpen) return null; // Only render if open
  return (
    <div className="modal-background">
      <div
        className="modal-container"
        style={{
          height: "85%",
          width: "85vw",
          maxWidth: 950,
          position: "relative",
        }}
      >
        <h2 className="modal-title">Golden Fiddle Portal</h2>
        {isOpen == "archive" ? (
          <>
            <div
              style={{
                height: "80%",
                overflow: "scroll",
                marginTop: 10,
              }}
            >
              {contentData ? (
                Object.keys(contentData).map((section) =>
                  renderSection(section, contentData[section])
                )
              ) : (
                <></>
              )}
            </div>
            <div
              style={{ position: "absolute", bottom: 40, right: 0, left: 0 }}
            >
              <button
                className="modal-button-close modal-button"
                onClick={onClose}
              >
                Go Back
              </button>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div
              style={{
                height: "85%",
                overflow: "scroll",
                marginTop: 10,
              }}
            >
              Class Videos Will Be Posted Here As They Come Out
              {currentClassesSections.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className="section-title"
                      style={{ textAlign: "left" }}
                    >
                      {item}
                    </div>
                    <ul
                      className="section-list"
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      {currentClasses[index].map((item, index0) => {
                        const currentIndexcurrentClasses =
                          globalIndexCurrentClasses; // Simpan index saat ini
                        globalIndexCurrentClasses++;
                        // setIndexNow(indexNow+1)
                        return (
                          <li
                            key={index0}
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "flex-start",
                              textAlign: "left",
                              paddingLeft: 20,
                            }}
                          >
                            {index0 + 1}.&nbsp;
                            <div
                              onClick={() => {
                                changeCourse(item.slug, true);
                                setSelectedKit(currentIndexcurrentClasses);
                                onClose();
                              }}
                              style={{ flex: 1, marginRight: 20 }}
                              className="golden-fiddle-currentclasses-link"
                            >
                              {item.name}
                            </div>
                            {/* {item.youtubeLink.length > 0 ? (
                            <div>
                              <a
                                href={item.youtubeLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link"
                              >
                                Youtube
                              </a>
                              <span className="link-divider">|</span>
                              <a
                                href={item.googleDriveLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link"
                              >
                                Handouts
                              </a>
                            </div>
                          ) : (
                            <div>Coming Soon...</div>
                          )} */}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
            <div
              style={{ position: "absolute", bottom: 40, right: 0, left: 0 }}
            >
              <button
                className="modal-button-close modal-button"
                onClick={onClose}
              >
                Go Back
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GoldenFiddle;
