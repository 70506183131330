import { useNavigate } from "react-router-dom";
import HeaderImageWhite from "../assets/fiddleschool-logo.png";
import Hamburger from "../assets/Hamburger.png";
import { GoPerson } from "react-icons/go";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useState, useEffect } from "react";

export default function NavBarHome(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window?.innerWidth]);
  return (
    <>
      <div className="navbar" style={{ zIndex: 10 }}>
        {props.page == "courses" ? (
          <div className="sidebarToggle">
            <div
              onClick={() => {
                props.toggleSidebar();
              }}
            >
              <img
                src={Hamburger}
                alt="hamburger"
                style={{ width: 32, height: 32, cursor: "pointer" }}
              />
            </div>
            <div
              onClick={() => {
                navigate("/courses");
              }}
            >
              <img
                src={HeaderImageWhite}
                alt="headerImage"
                style={{
                  width: 135,
                  objectFit: "cover",
                }}
                className={"navImage"}
              />
            </div>
            {isMobile ? (
              <div className={"no-decoration account-setting"}>
                <div
                  className="nav-item acoount-setting-nav account-setting-margin-left-mobile"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <GoPerson className="iconMobile" />
                  {open ? (
                    <MdKeyboardArrowUp className="iconMobile" />
                  ) : (
                    <MdKeyboardArrowDown className="iconMobile" />
                  )}
                </div>
                {open ? (
                  <ul className="account-setting-menu">
                    <li
                      className="account-setting-menu-list"
                      onClick={() => {
                        window.open(
                          "https://fiddleschool.store/account",
                          "_blank"
                        );
                      }}
                    >
                      My Account
                    </li>
                    <li
                      className="account-setting-menu-list"
                      onClick={() => {
                        window.open(
                          "https://fiddleschool.store/a/subscriptions/manage/",
                          "_blank"
                        );
                      }}
                    >
                      Manage Subscriptions
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <>
            <div
              className="navImage"
              onClick={() => {
                navigate("/courses");
              }}
              style={{ flex: 1 }}
            >
              <img
                src={HeaderImageWhite}
                alt="headerImage"
                style={{
                  cursor: "pointer",
                }}
                className="navbar-image"
              />
            </div>
            <>
            {isMobile ? (
              <div className={"no-decoration account-setting"}>
                <div
                  className="nav-item acoount-setting-nav account-setting-margin-left-mobile"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <GoPerson className="iconMobile" />
                  {open ? (
                    <MdKeyboardArrowUp className="iconMobile" />
                  ) : (
                    <MdKeyboardArrowDown className="iconMobile" />
                  )}
                </div>
                {open ? (
                  <ul className="account-setting-menu">
                    <li
                      className="account-setting-menu-list"
                      onClick={() => {
                        window.open(
                          "https://fiddleschool.store/account",
                          "_blank"
                        );
                      }}
                    >
                      My Account
                    </li>
                    <li
                      className="account-setting-menu-list"
                      onClick={() => {
                        window.open(
                          "https://fiddleschool.store/a/subscriptions/manage/",
                          "_blank"
                        );
                      }}
                    >
                      Manage Subscriptions
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            </>
          </>
        )}

        <div className="nav-item-logout-small">
          <div
            onClick={() => {
              localStorage.setItem("email", null);
              window.location.href = "/login";
            }}
            className="splash-login-button splash-button navbar-home-logout"
          >
            Log out
          </div>
        </div>
        <div className="nav-item-container nav-item-container-splash">
          <div className="nav-item-container-1">
            <div
              className={
                props.page == "courses" ? "nav-item-selected" : "nav-item"
              }
              onClick={() => {
                navigate("/courses");
              }}
            >
              Courses
            </div>
            <div
              onClick={() => {
                navigate("/tracks");
              }}
              className={
                props.page == "radio" ? "nav-item-selected" : "nav-item"
              }
            >
              Radio
            </div>
          </div>
          <div className="nav-item-container-2">
            <div
              onClick={() => {
                navigate("/circle");
              }}
              className={
                props.page == "circle" ? "nav-item-selected" : "nav-item"
              }
            >
              Community
            </div>
            <div
              onClick={() => {
                navigate("/retreats");
              }}
              className={
                props.page == "retreats"
                  ? "nav-item-selected"
                  : "nav-item"
              }
            >
              Retreats
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fiddleschool.store"
              className={"nav-item no-decoration"}
            >
              Shop
            </a>
            <div className={"no-decoration account-setting"}>
              <div
                className="nav-item acoount-setting-nav"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <GoPerson className="icon" />
                {open ? (
                  <MdKeyboardArrowUp className="icon" />
                ) : (
                  <MdKeyboardArrowDown className="icon" />
                )}
              </div>
              {open ? (
                <ul className="account-setting-menu">
                  <li
                    className="account-setting-menu-list"
                    onClick={() => {
                      window.open(
                        "https://fiddleschool.store/account",
                        "_blank"
                      );
                    }}
                  >
                    My Account
                  </li>
                  <li
                    className="account-setting-menu-list"
                    onClick={() => {
                      window.open(
                        "https://fiddleschool.store/a/subscriptions/manage/",
                        "_blank"
                      );
                    }}
                  >
                    Manage Subscriptions
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
            <div>
              <div
                onClick={() => {
                  localStorage.setItem("email", null);
                  window.location.href = "/login";
                }}
                className="splash-login-button splash-button navbar-home-logout"
              >
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="nav-links-visible-home-container">
        <nav className="nav-links-visible nav-links-visible-home">
          <div className="nav-item-container-1-small">
            <div
              onClick={() => {
                navigate("/courses");
              }}
              className={
                props.page == "courses" ? "nav-item-selected" : "nav-item"
              }
            >
              Courses
            </div>
            <div
              onClick={() => {
                navigate("/tracks");
              }}
              className={
                props.page == "radio" ? "nav-item-selected" : "nav-item"
              }
            >
              Tracks & Playlists
            </div>
            <div
              onClick={() => {
                navigate("/circle");
              }}
              className={
                props.page == "circle" ? "nav-item-selected" : "nav-item"
              }
            >
              Community
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fiddleschool.store"
              className={"nav-item nav-item-small no-decoration"}
            >
              Shop
            </a>
          </div>
        </nav>
      </div>
    </>
  );
}
